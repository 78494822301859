import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>Experience</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row gh-5">

				<div className="col-lg-12"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        {/* <h3 className="s_border">Experiences</h3> */}
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2024</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Mentored by Asia Champion Azlan Shah Kamaruzaman</span>
                                	Currently under mentorship & advanced training by Mr. Azlan Shah Kamaruzaman (Asia Champion) in Malaysia
                                </p>
                                <p className="d_timeline-text">
                                	<span className="d_title">Idemitsu Honda India Talent Cup 2024 </span>
                                	{/* <span className="d_company">Kyros Studio</span> */}
                                	Selected for the Idemitsu Honda India Talent Cup 2024 NSF 250 Super Sport.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2023</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title"> MRF MMSC FMSCI Indian National Motorcycle Racing C hampionship 2023 </span>
                                Petronas TVS Racing One Make Championship Apache RTR 200 Rookies, 6th overall with a best finish of 5th.
                                </p>
                                <p className="d_timeline-text">
                                	<span className="d_title">PETRONAS TVS RACING ONE MAKE CHAMPIONSHIP 2023</span>
                                	{/* <span className="d_company">Google Inc</span> */}
                                	MRF MMSC FMSCI Indian National Motorcycle Racing C hampionship 2023 STOCK UPTO 165CC NOVICE , BEST FINISH 16 th.
                                </p>
  
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2022 - 2023</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Motorcycle Circuit Racing Training</span>
                                    Professionally trained for motorcycle circuit racing at Apex Racing Academy (India) in 2022.
                                    <p>
                                    Professionally trained for motorcycle circuit racing from TVS Racing Training Academy (India) in 2023.
                                    </p>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>


                <div className="spacer-double"></div>
			</div>
		</div>
	);
}

export default hero;