import React from 'react';

const footer = () => {
    const currentYear = new Date().getFullYear();
    return(
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <a href="https://www.nexeor.com" target="_blank" rel="noreferrer">
                            <span className="copy">&copy; Copyright {currentYear} - Aaron Soni Fernandez |  
                                <span class="nexeor-tag"> Powered by 
                                    <b><a href="https://nexeor.com/"  target="_blank" rel="noreferrer" class="nexeor-link"> <img src="./img/nexeor-logo.png" width="15px" style={{marginTop: '-5px'}} alt="Nexeor" /> N E X E O R </a></b>
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <div className="social-icons">
                            <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a>
                            <a href="https://twitter.com" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a>
                            <a href="https://www.instagram.com/aaronnnn_44?igsh=MXZ0ZHZraW43MGN2Ng==" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default footer;