import React from 'react';
import AOS from 'aos';
AOS.init();

const whatido = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <h2>What I Do</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <div className="spacer-single"></div>
            <div className="row">
                <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_genius id-color-2"></i>
                            <div className="text">
                                <h3>Motorcycle Racing Training Path</h3>
                                <li>
                                    Professionally trained for motorcycle circuit racing at Apex Racing Academy (India) in 2022.
                                </li>
                                <li>
                                Professionally trained for motorcycle circuit racing from  TVS Racing Training Academy (INDIA) in 2023.
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4" 
                    data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_tools id-color-2"></i>
                            <div className="text">
                                <h3>Significant Racing Achievements in 2023</h3>
                                <li>
                                Recorded a best finish of 16th in the MRF MMSC FMSCI Indian National Motorcycle Racing Championship Stock Up to 165cc Novice in 2023.
                                </li>
                                <li>
                                Achieved 6th overall with a best finish of 5th in the Petronas TVS Racing One Make Championship Apache RTR 200 Rookies in 2023.
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_camera_alt id-color-2"></i>
                            <div className="text">
                                <h3>2024 Racing Milestone</h3>
                                <li>
                                Currently under mentorship & advanced training by Mr. Azlan Shah Kamaruzaman (Asia Champion) in Malaysia
                                </li>
                                <li>
                                Selected for the Idemitsu Honda India Talent Cup 2024 NSF 250 Super Sport in 2024.
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default whatido;