import React from 'react';
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import AnimatedProgressProvider from "../layout/AnimatedProgressProvider";

import AOS from 'aos';
AOS.init();


const hero = () => {
	return(
		<div className="v-center">
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2 data-aos="fade-up"
                        data-aos-delay="0"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true">About Me</h2>
	                <div className="space-border"
	                	data-aos="fade-up"
                        data-aos-delay="20"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true"
	                	></div>
	            </div>
	            <div className="col-md-10 text-center m-auto"
	            	data-aos="fade-up"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
	            	>
	            	<p>I, Aaron Soni Fernandez, born on October 10, 2008, in Thiruvananthapuram, Kerala,India, have always been passionate about motorcycle racing. 
						My journey began in 2022 with the Apex Racing Academy, and I further honed my skills in 2023 at the TVS Racing Training Academy. 
						Competing in the Petronas TVS Racing One Make Championship 2023 Apache RTR 200 Rookies, I secured a 5th place finish in one race and ranked 6th overall in the championship. 
						Representing team Apex Racing Academy and my region in the MRF MMSC FMSCI Indian National Motorcycle Racing Championship, I finished 16th among 23 competitors. 
						The highlight of my career came in 2024 when I earned a spot in the Honda Racing India’s Idemitsu Honda India Talent Cup NSF 250 Super Sport, placing in the top 11 out of 36 applicants. 
						Despite the challenges of 2023, I have learned a lot and am committed to improving my race craft.
                     </p>
					 <p style={{}}>
					 Striving for excellence and confident in achieving significant progress in the 2024 season!
					 </p>
	            </div>
			</div>

		</div>
		</div>
	);
}

export default hero;